html {
  overflow: visible;
}

body {
  min-width: 1328px;
  overflow: visible;
}

* {
  scrollbar-width: none;
}

* ::-webkit-scrollbar {
  display: none;
}
